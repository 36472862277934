header {
    position: relative;

}

.header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

}

.header-nav {
    position: absolute;
    min-height: max-content;
    background-color: transparent;
    width: 40%;
    right: 0;
    top: 0.85rem;
    z-index: 10;
    transition: opacity 0.3s ease-in-out;

}

.header-list-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}



a {

    color: #fff;
    text-decoration: none;
}

li {
    list-style: none;
}

.header-title {
    margin-left: 450px !important;
    z-index: 1000;
}

.nav-btn {
    cursor: pointer;
    font-size: 20px;
    color: #000;
    font-weight: 900;
    display: none;
    transition: 0.5s ease-in-out;
    z-index: 1000;
}

.header-nav-link {
    text-decoration: none;
    font-weight: 100;
    transition: 0.5s ease-in-out;

}

.header-nav-link:hover {
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
}

.header-title  {
    background-image: url('../Assets/Nature.jpg');
    background-size: cover;
    background-clip: text;
    -webkit-background-clip: text;  
    color: transparent;
  }
  
 
  .header-title-container {
        position: relative;
        display: inline-block;
        font-size: 24px;
  }
  
 .header-img{
    background-size: cover;
    background-clip: text;
    -webkit-background-clip: text;  
    width: 20px;
    height: 20px;
    margin: 20px 0;
    border-radius: 5px;
 }
@media only screen and (max-width: 1200px) {
    .header-title {
        margin-left: 100px !important;
    }

    .header-list-container {
        padding: 0px !important;
    }

    .header-nav {
        width: max-content;
        font-weight: 100;
        transition: 0.5s ease-in-out;
    }

    .header-nav:hover {

        font-weight: 600;

    }

    .header-list {
        padding: 0 20px;
    }

    li {
        margin: 5px 5px 0 5px;
    }


}

@media only screen and (max-width: 900px) {
    .header {
        justify-content: space-around;
    }

    .header-title-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 80%;
    }

    .header-title {
        font-size: 1.2rem;
        margin-left: 0px !important;
        
    }

    .header-list-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        height: 30vh;
        padding: 10px !important;

    }

    .header-nav {
        position: absolute;
        min-height: 30vh;
        left: 0;
        top: 3.75rem;
        width: 200px;
        background-color: rgba(0, 0, 0, 0.712);
    }

    .nav-btn {
        margin: 0 10px;
        display: block;
        background: transparent;
        color: #fff;
        border: none;
    }

    .nav-btn-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 20%;
        align-items: center;
    }

    .header-list {
        padding: 10px 0;
    }
    .header-nav-link{
     
        color: white;
      }
      
}
