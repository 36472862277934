.clients-content-container {
    max-width: 155vh;
    /* min-height: 30vh; */
    margin: 0 auto;
    background-color: rgb(235, 238, 241);
    
    text-align: center;
    
}
.clients-content-container h1{ margin-top: 0;
    padding:15px}

.clients-image-container {
    max-width: 120vh;

    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
}

.clients-image-container img {
    width: 130px;
    height: 130px;
    margin: 10px;
}