@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
/* 
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
} */
*,
::after,
::before {
  box-sizing: border-box;
}

body {
  /* font-family: 'Nunito', sans-serif; */
  margin: 0;
  /* font-family: "Poppins", sans-serif; */
   font-family: 'Centra', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* background-image: url('./Assets/bg2.jpg');*/
  background-attachment: fixed;


}

html {
  scroll-behavior: smooth;
}

.app {
  display: flex;
  flex-direction: column;
  min-width: 40vh;
  min-height: 100vh;
  background: rgba(126, 126, 126, 0.66) !important;
}

::-webkit-scrollbar {
  display: none;
}

.header {
  text-align: center;
  top: 0;
}

.header h3 {
  margin: 0;
  padding: 20px 5px;
}

h1{font-size: 28px;}
/* .footer { bottom: 0;} */
.header,
.footer {
  background-color: rgb(0 0 0 / 50%);
  color: white;

  min-width: 40vh;
  position: sticky;

  z-index: 1000;
}

.main {
  flex: 1;
  margin-bottom: -59px;
}

.main-content {
  position: relative;
}

.enquire-content,
.services-content,
.clients-content,
.testimonials-content,
.contacts-content {
  min-height: 10vh;
  min-width: 40vh;
}

.scroll-to-top-button {
  position: sticky;
  bottom: 100px;
  left: 92%;
  width: 60px;
  height: 60px;
  font-size: 20px;
  border: none;
  border-radius: 50%;
  background-color: #333;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0.75;
}

@media only screen and (max-width: 1150px) {
  /* .main {
    margin-bottom: 0;
  } */

  .scroll-to-top-button {
    right: 0;
    /* width: 30px;
    height: 30px; */
    font-size: 25px;
    font-weight: bold;
    bottom: 370px;
    z-index: 1000;

  }

}
