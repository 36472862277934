.footer-nav {
    min-height: max-content;
    background-color: transparent;
    margin: 15px;
    width:100%;

}

.footer-list-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around; 
     align-items: center;
   
}

footer {
    /* height: 6rem; */
    font-size: 14px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around; 
     align-items: center;
}

@media only screen and (max-width: 1200px) {
    a {
        color: #fff;
        text-decoration: none;
    }
    .footer-nav {
        margin: 0;
        /* width: max-content; */
    }
    .footer-list {
        padding: 10px 0;
    }
}