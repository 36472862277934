.testimonials-content-container {
    max-width: 155vh;
    /* min-height: 30vh; */
    margin: 0 auto;
    background-color: rgba(218, 169, 60, 0.85);
    display: flex;
    justify-content: center;
    /* flex-direction: column; */
    flex-wrap: wrap;
    align-items: center;
    overflow: hidden;
    min-width: 40vh;
    padding: 10px;
}
.testimonials-content-container h1 {
    margin: 0;
    padding: 10px;
    font-size: 28px;
}

.carousel.carousel-slider {
    padding-top: 30px;
    min-height: 17vh;
}

/* .testimonials-container{max-width: 500px;margin: 0 auto;} */

/* .carousel .slider-wrapper {
    width: 50%;
} */

/* .carousel-root {
    width: 80%;
} */

@media only screen and (min-width: 600px) and (max-width: 1050px ) {
    .testimonials-container {
        max-width: max-content;
        margin: 0 auto;
        font-size: 10px;
    }
    .testimonials-content-container h1 {
    
        font-size: 28px;
    }
}
@media only screen and (max-width: 600px)  {
    .testimonials-container {
        max-width: 350px;
        margin: 0 auto;
        font-size: 10px;
    }
  
}