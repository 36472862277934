
.contact-content-container {
    max-width: 155vh;
    /* min-height: 80vh; */
    margin: 0 auto;
    background-color: rgba(12, 33, 55, 1);
    flex-wrap: wrap;


}

.contact-content-container div h1 {
    margin: 0;
    padding: 10px;
}

.contact-content-container div {
    text-align: center;
    color: rgba(218, 169, 60, 0.85);
}


.contact-detail-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 10px;
}

.contact-detail-card-val img {
    width: 50px;
}

.contact-detail-card-val {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    min-width: 200px;
    padding: 10px;
    color: #fff !important;
    margin: 10px;
}


.contact-detail-card {

    min-height: 200px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 10px;

}

.contact-detail-card-val h3 {
    margin: 5px;
}


.fb-container {
    width: 100%;
    /* height: 300px; */
    padding: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    overflow: auto;
}

.message-textarea {
    width: 60%;
    /* height: 93%; */
    min-width:250px;
    /* max-height: 100px; */
    margin: 0px 0 8px;

}

.fb-details {
    display: flex;
    flex-direction: column;
    justify-content:space-around;
    width: 30%;
    min-width: 250px;

}

.fb-input {
    width: 100%;
    height: 100%;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.2);
    background-color: rgba(251, 246, 246, 0.2);
    font-size: 16px;
    margin: 5px;
    border-radius: 5px;
    padding: 10px;
    
    
}
.form-inp{
    display: flex;
    flex-direction: column;
}
input::placeholder,textarea::placeholder{
    color: #fff;
}

.fb-sbt-btn {
    width: 150px;
    height: 40px;
    border-radius: 25px;
    border: 0;
    background-color: rgb(53, 96, 94);
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    animation: colorTransition 2s infinite;
    margin: 20px 20px 40px;
}

.modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  
  .modal-heading {
    margin-bottom: 20px;
    color: #333;
  }
  
  .modal-text {
    margin-bottom: 20px;
    color: #666;
  }
  
  .modal-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .modal-button:hover {
    background-color: #0056b3;
  }
  

  /* .testimonials-container{
    display: flex;
  } */
  .testimonials-name{
    /* text-align: right; */
    margin-top: 30px;
    font-weight: bold;
  }

@media only screen and (max-width: 780px) {
    .message-textarea {
        max-width: 250px;
    }
 
}

@media only screen and (max-width: 400px) {
    .message-textarea {
        min-width: 180px !important;
    }
    .fb-details {
        display: flex;
        flex-direction: column;
        justify-content:space-around;
        width: 30%;
        min-width: 180px;
    
    }
}