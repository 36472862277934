

.services-content h1 {
    background-color: rgba(12, 33, 55, 1);
    text-align: center;
    color: #fff;
    margin: -5px 0;
    padding: 20px;

}

.services-content-container {
    max-width: 155vh;
    min-height: 80vh;
    margin: 0 auto;
    background-color: rgba(12, 33, 55, 1);
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;


}

.service-block {
    color: #fff;
    width: 370px;
    min-height: 100%;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.service-block-img {
    max-width: 200px;
    min-height: 50px;
    border-radius: 20%;
    margin: 5px;
    border: 2px solid #fff;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.service-block-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 85%;
    min-height: 30%;
    text-align: center;
}

.service-block-title p {
    text-align: center;
    width: 350px;
}

.service-block-title h3 {
    margin: 5px 0;
}

@media only screen and (max-width: 780px) {
    .service-block-img {
        min-width: 200px;
    }

    .service-block-title {
        font-size: 14px;
        width: 100px;
    }

    .services-content h1 {
        font-size: 28px;
    }
    .service-block-title p {
        text-align: center;
        width: 200px;
    }
    
    
    .services-content {
        padding: 5px;
    }

    .service-block {
        color: #fff;
        width: 250px;
    }

    .services-content-container {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;

    }
}

.fade-in-text {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.fade-in {
    opacity: 1;
}