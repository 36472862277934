.enquire-content {
    overflow: hidden;
    position: relative;

}

.enquire-content-image {
    width: 100%;
    height: 500px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    filter: blur(3px);
}

.enquire-content-container h1 {
    color: #fff;
}

.enquire-content-container {
    max-width: 550px;
    position: absolute;
    top: 25%;
    text-align: center;
    right: 8%;
}

.enquire-img-container img {
    width: 100%;
    height: 500px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: 3px solid #fff;
    border-radius: 50%;
}

.enquire-img-container {
    position: absolute;
    top: 5%;
    border-radius: 50%;
    left: 5%;
}

@keyframes colorTransition {
    0% { background-color: rgb(53, 96, 94); }
    50% { background-color: rgb(56, 126, 224); } /* Change to desired color */
    100% { background-color: rgb(53, 96, 94); }
  }
  
  .enquire-btn {
    width:max-content;
    height: 40px;
    border-radius: 25px;
    border: 0;
    background-color: rgb(53, 96, 94);
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); 
    animation: colorTransition 2s infinite;
  }
  



body::-webkit-scrollbar {
    display: none;
    overflow-y: hidden;
}

.enquire-link{
    padding: 50px;
}
@media only screen and (max-width: 1150px) {
    .enquire-content img {
        height: 170px;
    }

    .enquire-img-container img {
        height: 150px;
    }


    .enquire-img-container {
        top: 65%;
        left: 15%;
        transform: translate(-50%, -50%);
        max-width: 18%;
        max-width: 300px;
    }

    .enquire-content-container h1 {
        font-size: 18px;
    }

    .enquire-content-container {
        max-width: 310px;
        right: 8%;
    }

    .enquire-btn {
        width: max-content;
        height: 25px;
    }
}


@media only screen and (max-width: 500px) {
    .enquire-content-container {
        max-width: 215px;
        top: 25%;
        text-align: center;
        right: 0;
    }

    .enquire-content-container h1 {
        font-size: 14px;
    }

    .enquire-btn {
        width:max-content;
        height: 22px;
        font-size: 12px;
    }

    .enquire-img-container {
        top: 60%;
        left: 24%;
        transform: translate(-50%, -50%);
        max-width: 320px;
    }

    .enquire-image {
        max-height: 140px;
    }
}


@media only screen and (max-width: 400px) {
    .enquire-content-container {
        max-width: 180px;
      
    }}

 
      

      


    